var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wujieBox"},[_c('WujieVue',{attrs:{"width":"100%","height":"100%","alive":true,"name":"cms","url":_vm.cmsUrl,"sync":true}}),_c('wujieSelectVideo',{ref:"selecteMedia",attrs:{"checkedList":_vm.selectedVideos,"videoSize":_vm.videoSize,"videoType":_vm.videoType,"videoCheckedKey":_vm.videoCheckedKey,"videoEditId":_vm.videoEditId},on:{"selected":data => {
        _vm.eventSelected(data, 'media');
      }}}),_c('wujieSelectWord',{ref:"selecteWord",attrs:{"checkedList":_vm.selectedWords},on:{"selected":data => {
        _vm.eventSelected(data, 'word');
      }}}),_c('wujieSelectForm',{ref:"selecteForm",attrs:{"checkedList":_vm.selectedForms},on:{"selected":data => {
        _vm.eventSelected(data, 'form');
      }}}),_c('wujieSelectLive',{ref:"selectLive",attrs:{"checkedList":_vm.selectedLives,"liveEditId":_vm.liveEditId},on:{"selected":data => {
        _vm.eventSelected(data, 'live');
      }}}),_c('wujieSelectSubject',{ref:"selectSubject",attrs:{"webinar_id":_vm.webinar_id,"checkedList":_vm.selectedSubjects,"subjectType":_vm.subjectType},on:{"selected":data => {
        _vm.eventSelected(data, 'subject');
      }}}),_c('wujieSelectChannel',{ref:"selecteChannel",attrs:{"source_type":_vm.source_type,"source_id":_vm.webinar_id,"scene_type":_vm.scene_type,"checkedList":_vm.selectedChannels,"zIndex":_vm.zIndex},on:{"selected":data => {
        _vm.eventSelected(data, 'channel');
      }}}),_c('wujieChannelFans',{ref:"channelFans",attrs:{"source_type":_vm.source_type,"source_id":_vm.webinar_id,"scene_type":_vm.scene_type,"zIndex":_vm.zIndex || null}}),_c('wujieSelectPoster',{ref:"selectePoster",attrs:{"channel_code":_vm.channel_code,"source_type":_vm.source_type,"source_id":_vm.webinar_id,"posterName":_vm.posterName,"qrcodeUrl":_vm.qrcodeUrl,"zIndex":_vm.zIndex}}),_c('WujieInvitationCard',{ref:"invitationCard",attrs:{"cardId":_vm.inviteCard.cardId,"defaultCardInfo":_vm.inviteCard.defaultCardInfo,"sourceId":_vm.inviteCard.inviteSourceId,"sourceType":_vm.inviteCard.inviteSourceType,"shareUrl":_vm.inviteCard.shareUrl,"languageType":_vm.inviteCard.languageType},on:{"inviteCardSave":_vm.inviteCardSave}}),_c('wujieWeChatOfficialAccSetDialog',{ref:"wujieWeChatOfficialAccSetDialog",attrs:{"eventWechatOaData":_vm.eventWechatOaData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="wujieBox">
    <!--单例模式，name相同则复用一个无界实例，改变url则子应用重新渲染实例到对应路由 -->
    <WujieVue
      width="100%"
      height="100%"
      :alive="true"
      name="cms"
      :url="cmsUrl"
      :sync="true"
    ></WujieVue>
    <!-- 音视频 -->
    <wujieSelectVideo
      ref="selecteMedia"
      :checkedList="selectedVideos"
      @selected="
        data => {
          eventSelected(data, 'media');
        }
      "
      :videoSize="videoSize"
      :videoType="videoType"
      :videoCheckedKey="videoCheckedKey"
      :videoEditId="videoEditId"
    ></wujieSelectVideo>
    <!-- 文档列表 -->
    <wujieSelectWord
      ref="selecteWord"
      :checkedList="selectedWords"
      @selected="
        data => {
          eventSelected(data, 'word');
        }
      "
    ></wujieSelectWord>
    <!-- 表单 -->
    <wujieSelectForm
      ref="selecteForm"
      :checkedList="selectedForms"
      @selected="
        data => {
          eventSelected(data, 'form');
        }
      "
    ></wujieSelectForm>
    <!-- 直播 -->
    <wujieSelectLive
      ref="selectLive"
      :checkedList="selectedLives"
      :liveEditId="liveEditId"
      @selected="
        data => {
          eventSelected(data, 'live');
        }
      "
    ></wujieSelectLive>
    <!-- 专题 -->
    <wujieSelectSubject
      ref="selectSubject"
      :webinar_id="webinar_id"
      :checkedList="selectedSubjects"
      :subjectType="subjectType"
      @selected="
        data => {
          eventSelected(data, 'subject');
        }
      "
    ></wujieSelectSubject>
    <!-- 渠道列表 -->
    <wujieSelectChannel
      ref="selecteChannel"
      :source_type="source_type"
      :source_id="webinar_id"
      :scene_type="scene_type"
      :checkedList="selectedChannels"
      :zIndex="zIndex"
      @selected="
        data => {
          eventSelected(data, 'channel');
        }
      "
    ></wujieSelectChannel>
    <wujieChannelFans
      ref="channelFans"
      :source_type="source_type"
      :source_id="webinar_id"
      :scene_type="scene_type"
      :zIndex="zIndex || null"
    ></wujieChannelFans>
    <!-- 海报列表 -->
    <wujieSelectPoster
      ref="selectePoster"
      :channel_code="channel_code"
      :source_type="source_type"
      :source_id="webinar_id"
      :posterName="posterName"
      :qrcodeUrl="qrcodeUrl"
      :zIndex="zIndex"
    ></wujieSelectPoster>
    <WujieInvitationCard
      ref="invitationCard"
      :cardId="inviteCard.cardId"
      :defaultCardInfo="inviteCard.defaultCardInfo"
      :sourceId="inviteCard.inviteSourceId"
      :sourceType="inviteCard.inviteSourceType"
      :shareUrl="inviteCard.shareUrl"
      :languageType="inviteCard.languageType"
      @inviteCardSave="inviteCardSave"
    ></WujieInvitationCard>

    <wujieWeChatOfficialAccSetDialog
      ref="wujieWeChatOfficialAccSetDialog"
      :eventWechatOaData="eventWechatOaData"
    ></wujieWeChatOfficialAccSetDialog>
  </div>
</template>

<script>
  import cmsMixin from './mixin';
  export default {
    mixins: [cmsMixin]
  };
</script>
<style>
  .wujieBox {
    width: 100%;
    height: 100%;
    position: relative;
  }
</style>
